import useNavigationStore from '~/store/navigation'

export default function (context) {
    const { route, store, error, redirect } = context
    const navigationStore = useNavigationStore()

    const isLoginRoute = /^(\/login)(.)*/.test(route.path)
    const isPasswordResetRoute = /^(\/password-reset)(.)*/.test(route.path)
    const isOrgaIndex = route.name === 'organizations-idOrga'
    const isBusinessUnitIndex =
        route.name === 'organizations-idOrga-business-units-idBU'
    const user = store.getters['session/getUser']
    const isChargemapAdmin = store.getters['session/isSuperAdmin']
    const message = 'errors.no_access'

    if (isOrgaIndex)
        return redirect(`/organizations/${route.params.idOrga}/dashboard`)

    if (isBusinessUnitIndex)
        return redirect(
            `/organizations/${route.params.idOrga}/business-units/${route.params.idBU}/dashboard`
        )

    if (!isLoginRoute && !isPasswordResetRoute && user && !isChargemapAdmin) {
        return navigationStore
            .canAccessTo(context, route)
            .then((canAccessToThisRoute) => {
                if (!canAccessToThisRoute) {
                    return error({ statusCode: 403, message })
                }
            })
            .catch((_) => error({ statusCode: 403, message }))
    }
}
