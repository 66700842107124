type AnyObject = Record<string, any>

export const toCamel = (s: string): string =>
    s.replace(/([_]([a-z]|[1-9]))/gi, ($1) => $1.toUpperCase().replace('_', ''))

export const keysToCamel = (
    o: Object | Array<unknown>
): Object | Array<unknown> => {
    if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
        const n: AnyObject = {}
        Object.keys(o).forEach((k) => {
            n[toCamel(k)] = keysToCamel((o as AnyObject)[k])
        })
        return n
    }
    if (Array.isArray(o)) {
        return o.map((i) => keysToCamel(i))
    }
    return o
}

export const toSnake = (s: string) =>
    s
        .split(/(?=[A-Z]|[1-9])/)
        .join('_')
        .toLowerCase()

export const keysToSnake = (
    o: Object | Array<unknown>
): Object | Array<unknown> => {
    if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
        const n: AnyObject = {}
        Object.keys(o).forEach((k) => {
            n[toSnake(k)] = keysToSnake((o as AnyObject)[k])
        })
        return n
    }
    if (Array.isArray(o)) {
        return o.map((i) => keysToSnake(i))
    }
    return o
}

export const toCapitalize = (s: string): string =>
    s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
