
import Vue from 'vue'
import type { PropType } from 'vue'
import { mapActions, mapState } from 'pinia'
import useMobileFilterStore from '~/store/mobileFilter'
import IconArrowDown from '~/components/UI/icons/dropdown/IconArrowDown.vue'
import IconCheck from '~/components/UI/icons/dropdown/IconCheck.vue'
import { Filters } from '~/interfaces/filters'

interface Element {
    id: number
    name: string
    value: string
}

export default Vue.extend({
    name: 'AppFilter',
    components: { IconArrowDown, IconCheck },
    props: {
        value: {
            type: [String, Array, Boolean],
            default: null
        },
        elements: {
            type: Array as PropType<Array<Element>>,
            required: true
        }
    },
    computed: {
        ...mapState(useMobileFilterStore, {
            showBottomSheet: 'getShowBottomSheet',
            filters: 'getFilters'
        }),
        label(): String {
            if (this.elements.length < 1) {
                return 'Error not a value available'
            }
            if (
                this.value === undefined ||
                (Array.isArray(this.value) && this.value.length === 0)
            ) {
                return this.elements[0].name
            }
            if (Array.isArray(this.value)) {
                const values = this.value as string[]
                const elementsChecked = [] as string[]

                this.elements.forEach((element: Element) => {
                    values.forEach((value: string) => {
                        if (value === element.value && element.id !== 1)
                            elementsChecked.push(element.name)
                    })
                })

                if (elementsChecked.length === this.value.length - 1)
                    return this.elements[0].name as string

                const elementsCheckedToString = elementsChecked
                    .toString()
                    .replaceAll(',', ', ')

                // We truncate the string if it is too long
                return elementsCheckedToString.length > 17
                    ? `${elementsCheckedToString.substring(0, 17)}...`
                    : elementsCheckedToString
            }

            const index = this.elements.findIndex(
                (element: Element) => element.value === this.value
            )
            if (index >= 0) {
                return this.elements[index].name
            }

            return 'Error not a value available'
        },
        elementsAvailable(): Element[] {
            return this.elements?.slice(1)
        },
        BSfilter(this: any) {
            return {
                id: this._uid,
                name: this.label,
                value: this.value,
                options: this.elementsAvailable
            } as Filters
        }
    },
    watch: {
        filters: {
            handler(this: any, val) {
                const filter = val.find((f: any) => f.id === this._uid)
                if (this.showBottomSheet) {
                    this.updateLocalFilter(filter)
                }
            },
            deep: true
        }
    },
    mounted(this: any) {
        this.addFilter(this.BSfilter)
    },
    beforeDestroy(this: any) {
        this.removeFilter(this._uid)
    },
    methods: {
        ...mapActions(useMobileFilterStore, [
            'addFilter',
            'removeFilter',
            'updateFilter'
        ]),
        toggleDropdown(slotProps: any, shouldUpdateRef: boolean = false) {
            if (slotProps) {
                slotProps.toggleShowList()
                if (shouldUpdateRef) {
                    slotProps.setButtonRef(this.$refs.buttonRef)
                }
            }
        },
        update(this: any, value: string | boolean, slotProps: any) {
            this.$emit('input', value)
            this.updateFilter({
                id: this._uid,
                value
            })
            this.toggleDropdown(slotProps)
        },
        updateLocalFilter(this: any, filter: any) {
            if (filter.id === this._uid) {
                this.update(filter.value)
            }
        },
        isSelected(elementValue: string): boolean {
            if (Array.isArray(this.value)) {
                return this.value.includes(elementValue)
            }

            return this.value === elementValue
        }
    }
})
