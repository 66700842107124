import { defineStore } from 'pinia'
import { orderBy } from 'lodash'
import { DateTime } from 'luxon'
import {
    AggregationType,
    ChargeExportType,
    ConnectedCableCharge,
    DomesticConnectedCableExport,
    ExportFileType,
    InternalDomesticCharge,
    RefundStatus
} from '../interfaces/charge'
import { TypeZone } from '~/interfaces/zone'
import { ChargeFilter } from '@/interfaces/filters'
import {
    InternalCharge,
    ExternalCharge,
    DateAvailable
} from '@/interfaces/charge'
import { axiosWrapper } from '~/utilitary/storeWrapper'
import { keysToCamel } from '~/plugins/json/utils'

export const convertPaginate = (index: number, perPage: number) => {
    const offset = (index - 1) * perPage
    const limit = perPage
    return { offset, limit }
}
export const convertDate = (
    dateStartRaw: string | undefined,
    dateEndRaw: string | undefined
) => {
    let minStartDate = null
    let maxStartDate = null
    if (!dateStartRaw) {
        return { minStartDate, maxStartDate }
    }
    minStartDate = DateTime.fromISO(dateStartRaw).toISO()
    if (dateEndRaw) {
        maxStartDate = DateTime.fromISO(dateEndRaw).plus({ days: 1 }).toISO()
    }
    return { minStartDate, maxStartDate }
}

const useChargeStore = defineStore('charge', {
    state: () => ({
        externalCharges: [] as ExternalCharge[],
        externalChargesCount: 0 as number,
        internalCharges: [] as InternalCharge[],
        internalChargesCount: 0 as number,
        domesticCharges: [] as InternalDomesticCharge[],
        domesticChargesCount: 0 as number,
        chargingCharges: [] as InternalCharge[],
        chargingChargesCount: 0 as number,
        fleetCharges: [] as ExternalCharge[],
        fleetChargesCount: 0 as number,
        connectedCableCharges: [] as ConnectedCableCharge[],
        connectedCableChargesCount: 0 as number
    }),
    getters: {
        hasCharges(state) {
            return (
                state.externalChargesCount > 0 ||
                state.internalChargesCount > 0 ||
                state.domesticChargesCount > 0 ||
                state.chargingChargesCount > 0
            )
        },
        hasChargingCharges(state) {
            return state.chargingChargesCount > 0
        }
    },
    actions: {
        addCharge(charge: InternalCharge, filterPerPage: number) {
            this.chargingCharges.unshift(charge)
            this.chargingChargesCount += 1
            while (this.chargingCharges.length > filterPerPage) {
                this.chargingCharges.pop()
            }
        },
        updateCharge(charge: InternalCharge) {
            const hasSameId = (a: InternalCharge, b: InternalCharge) =>
                a.pass?.id === b.pass?.id ? b : a

            this.chargingCharges = this.chargingCharges?.map(
                (t: InternalCharge) => hasSameId(t, charge)
            )
        },
        deleteCharge(charge: InternalCharge, filterPerPage: number) {
            const removeIndex = this.chargingCharges
                .map((sess: InternalCharge) => sess.pass?.rfidUid)
                .indexOf(charge.pass?.rfidUid)
            if (removeIndex >= 0) {
                this.chargingCharges.splice(removeIndex, 1)
                this.chargingChargesCount -= 1
            }
            this.internalCharges.unshift(charge)
            this.internalChargesCount += 1
            while (this.internalCharges.length > filterPerPage) {
                this.internalCharges.pop()
            }
        },
        resetCharge() {
            this.internalCharges = []
            this.internalChargesCount = 0
            this.externalCharges = []
            this.externalChargesCount = 0
            this.chargingCharges = []
            this.chargingChargesCount = 0
            this.domesticCharges = []
            this.domesticChargesCount = 0
        },
        manageSubscribe(payload: {
            filterPerPage: number
            eventCharge: { type: string; charge: InternalCharge }
        }) {
            const { filterPerPage, eventCharge } = payload
            const charge = keysToCamel(eventCharge.charge) as InternalCharge
            if (eventCharge.type === 'STARTED') {
                return this.addCharge(charge, filterPerPage)
            }
            if (eventCharge.type === 'UPDATED') {
                return this.updateCharge(charge)
            }
            if (eventCharge.type === 'FINISHED') {
                return this.deleteCharge(charge, filterPerPage)
            }

            throw new Error(
                `reload charges with unknown type ${
                    eventCharge.type
                } on charge rfidUid ${
                    eventCharge.charge.pass?.rfidUid || 'unknown'
                }`
            )
        },
        fetchCharges(
            context: any,
            payload: {
                idBU?: number | string
                idOrga?: number | string
                finished: number | undefined
                isInternalBU?: boolean
                zoneType?: TypeZone | undefined
                chargeboxId?: number | string
                filters: ChargeFilter
                isSuperAdminView?: boolean
            }
        ) {
            const { dateRange, searchField, pages, refundStatus } =
                payload.filters
            // eslint-disable-next-line camelcase
            let params = {
                finished: payload.finished,
                zone_type: payload.zoneType,
                refund_status: undefined as RefundStatus[] | undefined,
                chargebox_id: undefined as number | string | undefined,
                offset: undefined as number | undefined,
                limit: undefined as number | undefined,
                keyword: undefined as string | undefined,
                min_start_date: undefined as string | undefined | null,
                max_start_date: undefined as string | undefined | null,
                order_by: 'date,desc'
            }
            const { offset, limit } = convertPaginate(
                pages.index,
                pages.perPage
            )
            if (offset) {
                params = { ...params, offset }
            }
            if (limit) {
                params = { ...params, limit }
            }
            if (refundStatus) {
                const refundStatusWithoutAllFilter = refundStatus.filter(
                    (filter) => filter !== RefundStatus.ALL
                )
                params = {
                    ...params,
                    refund_status: refundStatusWithoutAllFilter
                }
            }
            if (dateRange) {
                const { minStartDate, maxStartDate } = convertDate(
                    dateRange[0],
                    dateRange[1]
                )
                if (minStartDate) {
                    // eslint-disable-next-line camelcase
                    params = { ...params, min_start_date: minStartDate }
                }
                if (maxStartDate) {
                    // eslint-disable-next-line camelcase
                    params = { ...params, max_start_date: maxStartDate }
                }
            }

            const keyword = searchField || null
            if (keyword) {
                params = { ...params, keyword }
            }

            const chargeBox = payload.chargeboxId || undefined
            if (chargeBox) {
                params = { ...params, chargebox_id: chargeBox }
            }

            let urlAPI = 'charges'

            if (payload.isInternalBU) {
                urlAPI = 'fleet-charges'
            }

            if (!payload.isSuperAdminView) {
                urlAPI = `business-units/${payload.idBU}/charges`
                if (payload.isInternalBU) {
                    urlAPI = `business-units/${payload.idBU}/fleet-charges`
                }
            }

            return context.$axios
                .get(`/supervisionapi/${urlAPI}`, {
                    params
                })
                .then((response: any) => ({
                    count: response?.headers
                        ? parseInt(response?.headers['x-total-count'], 10)
                        : 0,
                    items: keysToCamel(response?.data?.items) || []
                }))
                .catch((e: any) => {
                    throw e
                })
        },
        fetchChargingCharges(
            context: any,
            payload: {
                idBU: number | string
                idOrga: number | string
                isInternalBU: boolean
                filters: ChargeFilter
            }
        ) {
            return this.fetchCharges(context, {
                ...payload,
                finished: 0,
                zoneType: TypeZone.PRIVATE,
                filters: {
                    ...payload.filters,
                    dateRange: undefined
                } as ChargeFilter
            }).then((response: { count: number; items: InternalCharge[] }) => {
                const charges = orderBy(response.items, 'startDate', 'desc')
                this.chargingCharges = charges
                this.chargingChargesCount = response.count
            })
        },
        fetchInternalCharges(
            context: any,
            payload: {
                idBU?: number
                idOrga?: number
                isInternalBU: boolean
                filters: ChargeFilter
                isSuperAdminView: boolean
            }
        ) {
            return this.fetchCharges(context, {
                ...payload,
                finished: 1,
                zoneType: TypeZone.PRIVATE,
                filters: { ...payload.filters }
            }).then((response: { count: number; items: InternalCharge[] }) => {
                const charges = orderBy(response.items, 'startDate', 'desc')
                this.internalCharges = charges
                this.internalChargesCount = response.count
            })
        },
        fetchDomesticCharges(
            context: any,
            payload: {
                idBU?: number
                isInternalBU: boolean
                filters: ChargeFilter
                isSuperAdminView: boolean
            }
        ) {
            return this.fetchCharges(context, {
                ...payload,
                finished: 1,
                zoneType: TypeZone.DOMESTIC,
                filters: { ...payload.filters }
            }).then(
                (response: {
                    count: number
                    items: InternalDomesticCharge[]
                }) => {
                    const charges = orderBy(response.items, 'startDate', 'desc')
                    this.domesticCharges = charges
                    this.domesticChargesCount = response.count
                }
            )
        },
        fetchConnectedCableCharges(
            context: any,
            payload: {
                idBU?: number
                isInternalBU: boolean
                filters: ChargeFilter
                isSuperAdminView: boolean
            }
        ) {
            return this.fetchCharges(context, {
                ...payload,
                finished: 1,
                zoneType: TypeZone.CONNECTED_CABLE,
                filters: { ...payload.filters }
            }).then(
                (response: {
                    count: number
                    items: ConnectedCableCharge[]
                }) => {
                    const charges = orderBy(response.items, 'startDate', 'desc')
                    this.connectedCableCharges = charges
                    this.connectedCableChargesCount = response.count
                }
            )
        },
        fetchById(
            context: any,
            payload: {
                id: string | number
                idBU: number
                isFleetCharges: boolean
            }
        ) {
            const { idBU, id, isFleetCharges } = payload
            let urlAPI = `/supervisionapi/business-units/${idBU}/charges/${id}`
            if (isFleetCharges) {
                urlAPI = `/supervisionapi/business-units/${idBU}/fleet-charges/${id}`
            }
            return context.$axios
                .$get(urlAPI)
                .then((res: any) => keysToCamel(res))
                .catch((e: any) => {
                    throw e.response
                })
        },
        fetchCoreRoamingById(
            context: any,
            payload: {
                id: string | number
                idBU: number
            }
        ) {
            const { id, idBU } = payload

            return context.$axios
                .$get(
                    `/supervisionapi/business-units/${idBU}/charges/core-roaming/${id}`
                )
                .then((res: any) => keysToCamel(res))
                .catch((e: any) => {
                    throw e.response
                })
        },
        updateChargeStatus(
            context: any,
            payload: {
                id: string | number
                refundStatus: RefundStatus.ALLOWED | RefundStatus.BLOCKED
            }
        ) {
            const { id } = payload
            const urlAPI = `/refundyapi/charges/${id}`

            return context.$axios
                .$patch(urlAPI, {
                    refund_status: payload.refundStatus
                })
                .then((res: any) => keysToCamel(res))
                .catch((e: any) => {
                    throw e
                })
        },
        fetchExternalCharges(
            context: any,
            payload: {
                idBU?: number
                filters: ChargeFilter
                isSuperAdminView: boolean | undefined
            }
        ) {
            const {
                isSuccess: success,
                dateRange,
                searchField,
                pages,
                billed
            } = payload.filters
            let params = {
                offset: undefined as number | undefined,
                limit: undefined as number | undefined,
                keyword: undefined as string | undefined,
                min_start_date: undefined as string | undefined | null,
                max_start_date: undefined as string | undefined | null,
                success,
                billed,
                order_by: 'date,desc'
            }

            const { offset, limit } = convertPaginate(
                pages.index,
                pages.perPage
            )
            if (offset) {
                params = { ...params, offset }
            }
            if (limit) {
                params = { ...params, limit }
            }
            if (dateRange) {
                const { minStartDate, maxStartDate } = convertDate(
                    dateRange[0],
                    dateRange[1]
                )
                if (minStartDate) {
                    // eslint-disable-next-line camelcase
                    params = { ...params, min_start_date: minStartDate }
                }
                if (maxStartDate) {
                    // eslint-disable-next-line camelcase
                    params = { ...params, max_start_date: maxStartDate }
                }
            }

            const keyword = searchField || null
            if (keyword) {
                params = { ...params, keyword }
            }

            let urlAPI = '/fleetapi/charges'

            if (!payload.isSuperAdminView) {
                urlAPI = `/fleetapi/business-units/${payload.idBU}/charges`
            }

            return context.$axios
                .$get(urlAPI, {
                    params
                })
                .then((res: any) => {
                    const externalCharges = keysToCamel(res.items)
                    this.externalCharges = externalCharges as ExternalCharge[]
                    this.externalChargesCount = res.count
                    return externalCharges
                })
                .catch((e: any) => {
                    throw new Error(e.response.message)
                })
        },
        fetchExternalCharge(
            context: any,
            payload: { idBU: string; idCharge: string }
        ) {
            const { idBU, idCharge } = payload
            return context.$axios
                .$get(`/fleetapi/business-units/${idBU}/charges/${idCharge}`)
                .then((res: any) => keysToCamel(res) as ExternalCharge)
                .catch((e: any) => {
                    throw new Error(e.response.message)
                })
        },
        fetchExportDateCharges(
            context: any,
            payload: {
                idBU: string
                idOrga: string
                isInternal: boolean
            }
        ) {
            const { idBU, isInternal } = payload
            const supervisionUrl = `supervisionapi/business-units/${idBU}/fleet-charges/dates-available`
            const fleetUrl = `/fleetapi/business-units/${idBU}/charges/dates-available`
            const url = isInternal ? supervisionUrl : fleetUrl

            const params = {}

            return context.$axios
                .$get(url, { params })
                .then((res: Array<DateAvailable> | DateAvailable) => {
                    if (!Array.isArray(res)) {
                        return [res]
                    }
                    return res
                })
                .catch((e: any) => {
                    throw new Error(e.response?.message || e)
                })
        },
        fetchExportDateBilledChargesExpenses(
            context: any,
            payload: {
                idBU: string
                idOrga: string
            }
        ) {
            const { idBU } = payload
            const url = `supervisionapi/business-units/${idBU}/billed-charges-expenses/dates-available`
            const params = {}
            return axiosWrapper
                .bind(context)('get', url, { params })
                .then((res: Array<DateAvailable> | DateAvailable) => {
                    if (!Array.isArray(res)) {
                        return [res]
                    }
                    return res
                })
        },
        fetchExport(
            context: any,
            payload: {
                idOrga: string
                idBU?: number
                startDate: string
                endDate: string
                exportAllBusinessUnits: boolean
                chargeTypes: Array<
                    ChargeExportType | DomesticConnectedCableExport
                >
                aggregationTypes: AggregationType[]
                fileType: ExportFileType
                supervised: boolean
            }
        ) {
            const {
                idOrga,
                idBU,
                startDate,
                endDate,
                chargeTypes,
                fileType,
                supervised
            } = payload

            let url = `exportyapi/organizations/${idOrga}`

            if (idBU) {
                url += `/business-units/${idBU}`
            }

            if (supervised) {
                url += '/supervised_export'
            } else {
                url += '/fleet_export'
            }

            const params = {
                start_date: startDate,
                end_date: endDate,
                charge_types: chargeTypes,
                aggregation_filters: payload.aggregationTypes,
                export_all_business_units: payload.exportAllBusinessUnits,
                file_type: fileType
            }

            return axiosWrapper
                .bind(context)('get', url, { params })
                .then((res: any) => res)
        }
    }
})

export default useChargeStore
