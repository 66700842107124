
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import AppMenu from '~/components/UI/AppMenu.vue'
import TeslaBanner from '~/components/others/TeslaBanner.vue'
import TeslaModal from '~/components/others/TeslaModal.vue'
import IconCloseMenu from '~/components/UI/icons/layout/IconCloseMenu.vue'

export default Vue.extend({
    components: { AppMenu, TeslaBanner, IconCloseMenu, TeslaModal },
    middleware: ['auth', 'init', 'navigation', 'flaggyConfigured'],
    data: () => ({
        heartbeatListenerId: 0,
        mobileMenuOpen: false,
        selectedMenu: 'administration',
        showTeslaModal: false
    }),

    computed: {
        ...mapGetters({
            getOrganization: 'organization/getOrganization'
        }),
        user() {
            return (this as any).$auth.user
        },
        teslaEnabled() {
            return this.$config.TESLA_ENABLED || false
        },
        isDev() {
            return this.$config.NUXT_ENV !== 'production'
        }
    },
    watch: {
        $route(to, from) {
            // when we completely change page, we scroll to top
            if (to.path !== from.path) {
                this.$nextTick(() => {
                    ;(this.$refs.content as HTMLElement).scrollTop = 0
                })
            }

            // when we change hash, we scroll under the title
            if (to.path === from.path && to.hash !== from.hash) {
                this.$nextTick(() => {
                    if ((this.$refs.content as HTMLElement).scrollTop > 156) {
                        ;(this.$refs.content as HTMLElement).scrollTop = 156
                    }
                })
            }
        }
    },
    mounted(this: any) {
        this.$menu.$on('toggle', (force: boolean) => {
            if (force === false || (this.mobileMenuOpen && force !== true)) {
                this.closeMobileMenu()
            } else {
                this.mobileMenuOpen = true
            }
        })
        const isBrowser = typeof window !== 'undefined'
        if (isBrowser) {
            if (this.$config.HELPDOCS_KEY) {
                try {
                    const windowHDLD = window as any
                    windowHDLD.hdlh = {
                        widget_key: this.$config.HELPDOCS_KEY,
                        primary_color: '#1E40AF',
                        brand: 'Chargemap Business',
                        logo: require('@/assets/images/helpdocs-chargemap.svg'),
                        launcher_button_image: require('@/assets/images/helpdocs-question-mark.svg'),
                        suggestions: ['category:j24pjsgdup'],
                        user: {
                            name: this.user.fullName,
                            email: this.user.email
                        },
                        onReady: () => {
                            windowHDLD.Lighthouse.hideButton()
                        },
                        onHide: () => {
                            windowHDLD.Lighthouse.hideButton()
                        }
                    }
                    this.initHelpdocs(
                        'https://lighthouse.helpdocs.io/load',
                        document
                    )
                } catch (error) {
                    this.$sentry.captureMessage('Doc widget error')
                }
            }

            try {
                const windowHDLD = window as any
                if (!windowHDLD.jimo && this.isDev) {
                    windowHDLD.jimo = []

                    const fullName =
                        this.user?.fullName ||
                        `${this.user?.firstName} ${this.user?.lastName}`

                    const s = document.createElement('script')

                    s.type = 'text/javascript'
                    s.async = true
                    s.src = 'https://undercity.usejimo.com/jimo-invader.js'

                    // Clé en dur car elle ne sert pour l'instant qu'à des tests pour l'équipe produit
                    windowHDLD.JIMO_PROJECT_ID =
                        '7720706e-1bc8-4634-a48e-a0b395a9f569'
                    document.getElementsByTagName('head')[0].appendChild(s)

                    windowHDLD.jimo.push([
                        'set',
                        'user:email',
                        [this.user.email]
                    ])
                    windowHDLD.jimo.push([
                        'do',
                        'identify',
                        [
                            this.user.id.toString(),
                            () => {
                                windowHDLD.jimo.push([
                                    'set',
                                    'user:name',
                                    [fullName]
                                ])
                            }
                        ]
                    ])
                }
            } catch (e) {
                this.$sentry.captureMessage('Jimo error')
            }
        }
    },
    beforeDestroy(this: any) {
        this.$menu.$off('toggle')
        clearInterval(this.heartbeatListenerId)
    },
    methods: {
        updateShowTeslaModal(value: boolean) {
            this.showTeslaModal = value
        },
        initHelpdocs(h: string, document: any) {
            const s = document.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.defer = true
            s.src = `${h}?t=${DateTime.now().toMillis()}`
            document.head.appendChild(s)
        },
        heartbeat(this: any) {
            return this.$autobahn
                .publish('staying.alive', [], undefined, undefined)
                .catch((error: any) => {
                    this.$sentry.captureException('staying.alive', error)
                })
        },
        closeMobileMenu() {
            this.mobileMenuOpen = false
        },
        switchMenu(id: string) {
            this.selectedMenu = id
        },
        goTo(location: string) {
            this.$router.push(location)
            this.mobileMenuOpen = false
        }
    }
})
