import { ref } from 'vue'
import { defineStore } from 'pinia'
import { ExpenseReport, ExpenseReportJSON } from '@/interfaces/expenseReport'
import { CallForFundAndExpenseReportsFilter } from '~/interfaces/filters'

const useExpenseReportStore = defineStore('exportReport', () => {
    const expenseReports = ref([] as ExpenseReport[])
    const expenseReportsCount = ref(null as number | null)

    const setExpenseReports = (newExpenseReports: ExpenseReport[]) => {
        expenseReports.value = newExpenseReports
    }

    const setExpenseReportsCount = (count: number) => {
        expenseReportsCount.value = count
    }

    const fetchExpenseReports = (
        context: any,
        payload: {
            idOrga: number | undefined
            idBU: number | undefined
            filters: CallForFundAndExpenseReportsFilter
        }
    ) => {
        const { filters } = payload
        const { pages, minStartDate, state, keyword } = filters
        const { idOrga, idBU } = payload
        const offset = (pages.index - 1) * pages.perPage || 0
        const limit = pages.perPage || 20
        const params = {
            offset,
            limit,
            min_start_date: minStartDate || null,
            state: state || null,
            keyword
        }
        let url = `refundyapi`
        if (idOrga) {
            url += `/organizations/${idOrga}`
        }
        if (idBU) {
            url += `/business-units/${idBU}`
        }
        url += `/expense_reports`

        return context.$axios
            .get(url, { params })
            .then(
                (res: {
                    headers: {
                        'x-total-count': string
                    }
                    data: {
                        items: ExpenseReportJSON
                    }
                }) => {
                    const result = context.$toCamel(res.data)
                    setExpenseReports(result.items)
                    const count = parseInt(res.headers['x-total-count'], 10)
                    setExpenseReportsCount(count)
                    return result
                }
            )
            .catch((e: any) => {
                throw e
            })
    }

    return {
        setExpenseReports,
        setExpenseReportsCount,
        fetchExpenseReports,
        expenseReports,
        expenseReportsCount
    }
})

export default useExpenseReportStore
